import { IDoctorForm } from "../types/models/Doctor";
import { IPatientForm } from "../types/models/Patient";

type FactoryType = {
  patient: IPatientForm
  doctor: IDoctorForm
}

export const Factory: FactoryType = {
  patient: { first_name: "", last_name: "", ci: "", phone: "" },
  doctor: { name: "", ci: "", email: "", username: "", password: "", password_confirmation: "", active: false, entry_time: '09:00', exit_time: "17:00" }
}